const base = 'dashboard'
const dashboard = [
  {
    path: '/' + base + '/calendars/:id.html',
    name: 'dashboardAssistent',
    component: () => import('@/components/dashboard/parts/assistentList/')
  },
  {
    path: '/' + base + '/organisations.html',
    name: 'dashboardOganisationSelect',
    component: () => import('@/components/dashboard/parts/organisations/')
  },
  {
    path: '/' + base + '/services.html',
    name: 'dashboardServices',
    component: () => import('@/components/dashboard/parts/services/')
  },
  {
    path: '/' + base + '/premium.html',
    name: 'dashboardPremium',
    component: () => import('@/components/dashboard/parts/premium/')
  },
  {
    path: '/' + base + '/webinar.html',
    name: 'dashboardWebinar',
    component: () => import('@/components/dashboard/parts/webinar/')
  },
  {
    path: '/' + base + '/logs.html',
    name: 'dashboardLogs',
    component: () => import('@/components/dashboard/parts/logs/')
  },
  {
    path: '/' + base + '/webhooks/:id.html',
    name: 'dashboardWebhook',
    component: () => import('@/components/dashboard/parts/webhooks/')
  },
  {
    path: '/' + base + '/statistic/events.html',
    name: 'dashboardEventsBooking',
    component: () => import('@/components/dashboard/parts/eventBookings/')
  },
  {
    path: '/' + base + '/webhooks/all.html',
    name: 'dashboardWebhooks',
    component: () => import('@/components/dashboard/parts/webhooks/')
  },
  {
    path: '/' + base + '/user-statistic.html',
    name: 'dashboardStatistic',
    component: () => import('@/components/dashboard/parts/statistic/')
  },
  {
    path: '/' + base + '/credits.html',
    name: 'dashboardCredits',
    component: () => import('@/components/dashboard/parts/credits/')
  },
  {
    path: '/' + base + '/permissions/:part/:action.html',
    name: 'dashboardPermissions',
    component: () => import('@/components/dashboard/parts/permissions/')
  },
  {
    path: '/' + base + '/qr-code/:id.html',
    name: 'dashboardQrCode',
    component: () => import('@/components/dashboard/parts/qrCode/')
  },
  {
    path: '/' + base + '/data-center.html',
    name: 'dashboardDataCenter',
    component: () => import('@/components/dashboard/parts/dataCenter/')
  },
  {
    path: '/' + base + '/check-in/:id.html',
    name: 'dashboardCheckIn',
    component: () => import('@/components/dashboard/parts/checkIn/')
  },
  {
    path: '/' + base + '/buy-credits.html',
    name: 'dashboardBuyCredits',
    component: () => import('@/components/dashboard/parts/buyCredits/')
  },
  {
    path: '/' + base + '/ical-server/:id.html',
    name: 'dashboardIcalServer',
    component: () => import('@/components/dashboard/parts/icalServer/')
  },
  {
    path: '/' + base + '/:edit/target-pages.html',
    name: 'dashboardTargetPage',
    component: () => import('@/components/targetPage/multi.vue')
  },
  {
    path: '/' + base + '/:edit/target-page/:id.html',
    name: 'dashboardSpecificTargetPage',
    component: () => import('@/components/targetPage/index.vue')
  },
  {
    path: '/' + base + '/download-center/:id.html',
    name: 'dashboardDownloadCenter',
    component: () => import('@/components/dashboard/parts/downloadCenter/')
  },
  {
    path: '/' + base + '/data-processing-contract.html',
    name: 'dashboardDataProcessingContract',
    component: () =>
      import('@/components/dashboard/parts/dataProcessingContract/')
  },
  {
    path: '/' + base + '/help.html',
    name: 'dashboardHelp',
    component: () => import('@/components/dashboard/parts/help/')
  },
  {
    path: '/' + base + '/backup.html',
    name: 'dashboardBackup',
    component: () => import('@/components/dashboard/parts/backup/')
  },
  {
    path: '/' + base + '/delete-account.html',
    name: 'dashboardDeleteAccount',
    component: () => import('@/components/dashboard/parts/deleteAccount/')
  },
  {
    path: '/' + base + '/reminder/:id.html',
    name: 'dashboardReminder',
    component: () => import('@/components/dashboard/parts/reminder/')
  },
  {
    path: '/' + base + '/defaultMessages/:id.html',
    name: 'dashboardDefaultMessages',
    component: () => import('@/components/dashboard/parts/defaultMessages/')
  },
  {
    path: '/' + base + '/ressources.html',
    name: 'dashboardRessources',
    component: () => import('@/components/dashboard/parts/ressources/')
  },
  {
    path: '/' + base + '/membership.html',
    name: 'dashboardMembership',
    component: () => import('@/components/dashboard/parts/membership/')
  },
  {
    path: '/' + base + '/customer/:id.html',
    name: 'dashboardCRM',
    component: () => import('@/components/dashboard/parts/crm/')
  },
  {
    path: '/' + base + '/customer/all.html',
    name: 'dashboardCRMall',
    component: () => import('@/components/dashboard/parts/crm/')
  },
  {
    path: '/' + base + '/customer/add/:id.html',
    name: 'dashboardAddCustomer',
    component: () => import('@/components/dashboard/parts/addCustomer')
  },
  {
    path: '/' + base + '/settings.html',
    name: 'dashboardSettings',
    component: () => import('@/components/dashboard/parts/settings/')
  },
  {
    path: '/' + base + '/publish/:id.html',
    name: 'dashboardPublish',
    component: () => import('@/components/dashboard/parts/publish/')
  },
  {
    path: '/' + base + '/page-builder/:open/:id.html',
    name: 'dashboardPageBuilder',
    component: () => import('@/components/dashboard/parts/pageBuilder/')
  },
  {
    path: '/' + base + '/interessentChat.html',
    name: 'dashboardInteressentChat',
    component: () => import('@/components/dashboard/parts/interessentChat/')
  },
  {
    path: '/' + base + '/calendar/:id/delete.html',
    name: 'dashboardAssistentDelete',
    component: () => import('@/components/dashboard/parts/assistentDelete/')
  },
  {
    path: '/' + base + '/calendar/integration.html',
    name: 'dashboardCalendarIntegration',
    component: () => import('@/components/dashboard/parts/calendarIntegration')
  },
  {
    path: '/' + base + '/form/:id.html',
    name: 'dashboardFormBuilder',
    component: () => import('@/components/dashboard/parts/formBuilder/')
  },
  {
    path: '/' + base + '/form.html',
    name: 'dashboardFormBuilders',
    component: () => import('@/components/dashboard/parts/formBuilder/')
  },
  {
    path: '/' + base + '/holidays.html',
    name: 'dashboardHolidays',
    component: () => import('@/components/dashboard/parts/holidays/')
  },
  {
    path: '/' + base + '/holidays/:id.html',
    name: 'dashboardHoliday',
    component: () => import('@/components/dashboard/parts/holidays/')
  },
  {
    path: '/calendar-assistent/:id.html',
    name: 'dashboardAssistentEdit',
    component: () => import('@/components/dashboard/parts/assistentEdit/')
  },
  {
    path: '/dashboard.html',
    name: 'dashboard',
    component: () => import('@/components/dashboard/parts/')
  },
  {
    path: '/' + base + '/search.html',
    name: 'dashboardSearch',
    component: () => import('@/components/dashboard/parts/search')
  },
  {
    path: '/' + base + '/:user/:form/:entryID/chat.html',
    name: 'dashboardChat',
    component: () => import('@/components/dashboard/parts/chat')
  },
  {
    path: '/' + base + '/:user/:form/:entryID/bookingDetails.html',
    name: 'dashboardBookingDetails',
    component: () => import('@/components/dashboard/parts/bookingDetails')
  },
  {
    path: '/' + base + '/logout.html',
    name: 'dashboardLogout',
    component: () => import('@/components/dashboard/parts/logout')
  },
  {
    path: '/' + base + '/user/:id.html',
    name: 'dashboardProfile',
    component: () => import('@/components/dashboard/parts/profile')
  },
  {
    path: '/' + base + '/smtp.html',
    name: 'dashboardSMTP',
    component: () => import('@/components/dashboard/parts/smtp')
  },
  {
    path: '/' + base + '/messageCenter.html',
    name: 'dashboardMessageCenter',
    component: () => import('@/components/dashboard/parts/messageCenter')
  },
  {
    path: '/' + base + '/bookingCalendar.html',
    name: 'bookingCalendar',
    component: () => import('@/components/dashboard/parts/bookingCalendar')
  },
  {
    path: '/' + base + '/bookingCalendar/date/:date.html',
    name: 'bookingCalendarDate',
    component: () => import('@/components/dashboard/parts/bookingCalendar')
  },
  {
    path: '/' + base + '/bookingCalendar/:id/date/:date.html',
    name: 'specificBookingCalendar',
    component: () => import('@/components/dashboard/parts/bookingCalendar')
  },
  {
    path: '/' + base + '/bookingCalendar/:id/:time.html',
    name: 'dateEntriesBookingCalendar',
    component: () => import('@/components/dashboard/parts/bookingCalendar')
  }
]

export { dashboard }
