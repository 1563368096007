const search = {
  state: {
    search: {
      keyword: '',
      results: {
        bookings: []
      }
    }
  },
  actions: {

  },
  mutations: {
    setSearch (store, data) { store.search.keyword = data.keyword; store.search.results = Object.assign(store.search.results, data.results) }
  },
  getters: {
    search (store) { return store.search }
  }
}

export { search }
