import Vue from 'vue'

const reminder = {
  state: {
    reminder: {}
  },
  actions: {
    getReminder (store, data) {
      if (typeof store.getters.reminder[data.ID] !== 'undefined') {
        return data.callback(store.getters.reminder[data.ID])
      }
      Vue.prototype.$flix_post({
        url: 'reminder/get',
        data: {
          user: store.getters.user.md5_id,
          ID: data.ID
        },
        callback: function (ret) {
          if (ret.data) {
            var send = {}
            send[data.ID] = ret.data
            store.commit('setReminders', send)
            data.callback(ret.data)
          }
        }
      })
    },
    saveReminder (store, data) {
      Vue.prototype.$flix_post({
        url: 'reminder/save',
        data: {
          user: store.getters.user.md5_id,
          ID: data.ID,
          filter: {
            trigger_target: 'appointment begin'
          },
          data: data.data

        },
        callback: function (ret) {
          window.console.log(ret)
        }
      })
    }
  },
  mutations: {
    setReminders (state, data) {
      state.reminder = data
    }
  },
  getters: {
    reminder (store) { return store.reminder },
    defaultReminder (store) {
      var d = new Date()
      return {
        ID: d.getTime(),
        to: '',
        format: 'email',
        field: '',
        title: '',
        msg: '',
        survey: '',
        manual: '',
        trigger_int: 1,
        trigger_format: 'days',
        trigger_prepend: 'before',
        trigger_target: 'appointment begin',
        trigger_specific_date: '',
        date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes(),
        status: 'active'
      }
    }
  }
}

export { reminder }
