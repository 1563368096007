<template>
  <div id="app">
    <div>
      <transition name="flixFadeIn">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      loaded: true
    }
  },
  methods: {
    getCheckRoute () {
      setTimeout(function () {
        if (this.$route.name === null) {
          this.$router.push({ name: 'signInTranslation', params: { lang: this.$i18n.locale } })
        }
      }.bind(this), 1000)
    }
  },
  mounted () {
    this.getCheckRoute()
    this.$store.commit('setActiveOrganisation')
    if (this.$store.getters.user.language) {
      this.$i18n.locale = this.$store.getters.user.language
    }
  }

}

</script>
<style lang="sass" scoped>
</style>
<style lang="sass">
  @import '@/assets/style/sass/stylesheet/flix_color_style.scss'
  @import '@/assets/style/sass/stylesheet/flix_bootstrap_style.scss'

  .flixFadeIn-enter .flixFadeIn-leave
    opacity: 0
    transform: scaleY(0)
    transform-origin: top

  .flixFadeIn-enter-active
    animation: flixFadeIn 0.3s ease-in-out
    transform-origin: top
    opacity: 0
    animation-delay: 0.3s

  .flixFadeIn-leave-active
    animation: flixFadeIn 0.3s reverse
    transform-origin: top

  @keyframes flixFadeIn
    from
      opacity: 0
      transform: scaleY(0)
    to
      opacity: 1
      transform: scaleY(1)
</style>
