import Vue from 'vue'
const credits = {
  state: {
    credits: false
  },
  actions: {
    getCredits (store, data) {
      if (store.getters.credits) {
        data.callback()
        return false
      }
      Vue.prototype.$flix_post({
        url: 'credit/get',
        data: { user: data.user },
        callback: function (ret) {
          if (ret.data[0]) {
            store.commit('setCredits', ret.data[1])
            data.callback()
            return false
          }
        }
      })
    }
  },
  mutations: {
    setCredits (store, data) {
      store.credits = data
    },
    addCredits (store, data) {
      store.credits.available *= 1
      store.credits.available += data
      store.credits.all *= 1
      store.credits.all += data
    }
  },
  getters: {
    credits (store) { return store.credits }
  }
}

export { credits }
