import Vue from 'vue'
import VueI18n from 'vue-i18n'

import getLang from './getLang.js'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'de',
  messages: {
    de: getLang('de'),
    en: getLang('en'),
    es: getLang('es'),
    fr: getLang('fr')
  }
})
