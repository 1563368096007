const saveAccessToken = (header) => {
  if (typeof header.get('access-token') !== 'undefined' && header.get('access-token')) {
    localStorage.setItem('flix_login_token', JSON.stringify({ access: header.get('access-token'), refresh: header.get('refresh-token') }))
  }
}

const additionalHeader = (header) => {
  if (typeof localStorage.getItem('flix_orga') === 'string') {
    header.Orga = localStorage.getItem('flix_orga')
  }

  if (typeof localStorage.getItem('flix_login_token') === 'string') {
    header.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('flix_login_token')).access
  }

  return header
}

const setRefreshToken = async (status, data, options, header) => {
  if (status !== 401) {
    return false
  }

  if (typeof localStorage.getItem('flix_login_token') === 'string') {
    header.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('flix_login_token')).refresh
  }

  const refresh = await fetch(options.url + '/' + options.lang + '/user/refresh_token', {
    method: 'POST',
    headers: header,
    body: JSON.stringify({
      user: JSON.parse(atob(localStorage.getItem('flix_login'))).md5_id
    })
  })

  if (refresh.status === 401) {
    localStorage.removeItem('flix_login')
    localStorage.removeItem('flix_login_token')
    location.reload()
    return false
  }

  saveAccessToken(refresh.headers)
  header = additionalHeader(header)

  const post = await fetch(options.url + '/' + options.lang + data.url, {
    method: 'POST',
    body: JSON.stringify(data.data),
    headers: header
  })

  const jsonData = await post.json()

  setCallback(data, jsonData)
}

const setCallback = (data, jsonData) => {
  if (typeof data.callback === 'function') {
    data.callback({ data: jsonData })
  } else { console.log(jsonData) }
}

export default {

  install (Vue, options) {
    Vue.prototype.$flix_post = async function (data, url) {
      if (typeof url === 'undefined') {
        options.url = options.post_url
      } else {
        options.url = options[url]
      }

      let headers = {
        'Content-Type': 'application/json; charset=UTF-8'
      }
      headers = additionalHeader(headers)

      if (typeof data.data !== 'object') {
        data.data = {}
      }

      if (data.url[0] !== '/') {
        data.url = '/' + data.url
      }

      const post = await fetch(options.url + '/' + options.lang + data.url, {
        method: 'POST',
        body: JSON.stringify(data.data),
        headers
      })

      const jsonData = await post.json()

      saveAccessToken(post.headers)

      setRefreshToken(post.status, data, options, headers)

      setCallback(data, jsonData)
    }
    Vue.prototype.$flix_url = options.url
    Vue.prototype.$flix_brand = options.brand
  }

}
