import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugin from './plugin/'
import i18n from './language'
import CKEditor from '@ckeditor/ckeditor5-vue'
import quickstart from 'bookingflix_quickstart'
import calendarItems from 'bookingflix_calendar_items'
// import { VuePlausible } from 'vue-plausible'
require('@/plugin/bf-framework.js')
require('@/plugin/calendar.js')

/*
Vue.use(VuePlausible, {
  domain: 'bookingflix.com',
  trackLocalhost: true
})
Vue.$plausible.enableAutoPageviews()
*/

Vue.use(calendarItems)
Vue.use(quickstart)
Vue.config.productionTip = false

Vue.use(CKEditor)
Vue.use(plugin, {
  post_url: process.env.VUE_APP_API_URL,
  url: process.env.VUE_APP_URL,
  brand: process.env.VUE_APP_BRAND,
  lang: process.env.VUE_APP_LANG
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#flix_dashboard')
