const admin = [
  {
    path: '/admin/page-builder.html',
    name: 'adminPageBuilder',
    component: () => import('@/components/admin/pageBuilder/list')
  },
  {
    path: '/admin/redirect/:string.html',
    name: 'adminRedirect',
    component: () => import('@/components/admin/redirect')
  }

]

export { admin }
