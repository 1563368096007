import Vue from 'vue'

const messages = {
  state: {
    messages: false,
    startScreen: true
  },
  mutations: {
    setMessages (store, data) { if (!store.messages) { store.messages = {} } store.messages = Object.assign(store.messages, data) },
    cleanMessages (store) { store.messages = false },
    closeStartScreen (store) { store.startScreen = false }
  },
  actions: {
    getMessages (store, callback) {
      if (store.getters.messages !== false) {
        callback()
        return false
      }

      Vue.prototype.$flix_post({
        url: 'newscenter/view/userchats_all',
        data: { user: store.getters.user.md5_id },
        callback: function (ret) {
          store.commit('setMessages', {})

          if (ret.data[0]) {
            store.commit('setMessages', { userchat: ret.data[1] })
          }

          Vue.prototype.$flix_post({
            url: 'newscenter/view/terminflix_news',
            data: { user: store.getters.user.md5_id },
            callback: function (ret) {
              if (ret.data[0]) {
                store.commit('setMessages', { flixNews: ret.data[1] })
              }
              callback()
            }
          })
        }
      })
    }
  },
  getters: {
    messages (store) { return store.messages }
  }
}

export { messages }
