import Vue from 'vue'

const organisations = {
  state: {
    organisations: false,
    organisationsKey: 0,
    organisationMemberKey: 0,
    organisationActive: false
  },
  actions: {
    getOrganisations (store, callback) {
      if (store.state.organisations) {
        callback(store.state.organisations)
        return false
      }
      Vue.prototype.$flix_post({
        url: 'organisation/get',
        data: { user: store.getters.user.md5_id },
        callback: function (ret) {
          if (!ret.data[0]) {
            callback()
            return false
          }
          store.commit('setOrganisations', { data: ret.data, callback: callback })
        }
      })
    }
  },
  mutations: {
    setActiveOrganisation (store) {
      if (typeof localStorage.getItem('flix_orga') !== 'string') {
        store.organisationActive = false
        return false
      }
      let orga = localStorage.getItem('flix_orga')
      if (typeof orga === 'string' && orga) {
        orga = atob(orga)
        store.organisationActive = orga.split('-')[1]
      }
    },
    updateOrganisationMembers (store) {
      store.organisationMemberKey++
    },
    clearOrganisations (store, data) {
      store.organisations = 0
    },
    setOrganisations (store, data) {
      if (data.data[0]) {
        store.organisations = data.data[1]
        store.organisationsKey += 1
        data.callback(data.data[1])
      } else {
        store.organisations = false
      }
    }
  },
  getters: {
    organisationName (store) { return new org(Vue.prototype).getOrganisationName(store) }
  }
}

export { organisations }

const org = function (parent) {
  this.getOrganisationName = function (orgs) {
    let ret = false
    if (typeof orgs.organisations !== 'object') {
      return false
    }
    orgs.organisations.forEach(function (org) {
      if (org.ID === orgs.organisationActive) {
        ret = org
      }
    })
    return ret
  }
}
