import Vue from 'vue'

const bookings = {
  state: {
    bookings: {},
    bookingHash: new Date().getTime(),
    newBooking: false,
    bookingsLoad: 0,
    bookingsCount: 0,
    bookingUpdate: 0,
    deletionList: 0
  },
  actions: {
    getDeletionList(store, data) {
      var dl = store.getters.deletionList * 1
      if (dl) {
        if (dl === 0 || dl === '0') {
          data.callback(false)
        } else {
          data.callback(store.getters.deletionList)
        }
        return false
      }

      Vue.prototype.$flix_post({
        url: 'booking/deletion_list',
        data: {
          user: data.user,
          filter: 'list'
        },
        callback: function (ret) {
          if (ret.data[0]) {
            ret.data[1].deletion = ret.data[1].deletion * 1
            store.commit('setDeletionList', ret.data[1].deletion)
            if (ret.data[1].deletion === 0 || ret.data[1].deletion === '0') {
              data.callback(false)
              return false
            }
            data.callback(ret.data[1].deletion)
          }
        }
      })
    },
    getBookingPage(store, data) {
      var send = {
        ID: data.ID
      }
      if (typeof data.user !== 'undefined') {
        send.user = data.user
      }
      if (typeof data.username !== 'undefined') {
        send.username = data.username
      }
      Vue.prototype.$flix_post({
        url: 'pagebuilder/targetpage/get',
        data: send,
        callback: function (ret) {
          if (
            typeof ret.data[1] === 'object' &&
            typeof ret.data[1].content !== 'undefined'
          ) {
            data.callback([ret.data[1].content, ret.data[2], ret.data[1].meta])
            return false
          } else {
            Vue.prototype.$flix_post({
              url: 'pagebuilder/bookingpage/get',
              data: send,
              callback: function (ret) {
                if (
                  typeof ret.data[1] === 'undefined' ||
                  typeof ret.data[1].content === 'undefined'
                ) {
                  data.callback([false, ret.data[2]])
                  return false
                }
                data.callback([ret.data[1].content, ret.data[2]])
              }
            })
          }
        }
      })
    },
    getBookings(store, callback) {
      if (
        typeof store.getters.bookings === 'object' &&
        Object.keys(store.getters.bookings).length
      ) {
        callback()
        return false
      }
      if (Object.keys(store.getters.assistents).length === 0) {
        callback()
        return false
      }
      var user = Vue.prototype.$getUserVariables()
      store.commit('deleteBookingsLoad')
      store.getters.assistents.forEach(function (assistent) {
        if (typeof assistent.ID === 'undefined') {
          store.commit('setBookingsLoad')
        }
        if (
          Object.keys(store.getters.assistents).length ===
          store.getters.bookingsLoad
        ) {
          callback()
          return false
        }
        var data = {}
        Vue.prototype.$flix_post({
          url: 'booking/get',
          data: { user: user.user.md5_id, ID: assistent.ID },
          callback: function (ret) {
            store.commit('setBookingUpdateKey', ret.data[2])
            data[assistent.ID] = []
            if (ret.data[0]) {
              data[assistent.ID] = ret.data[1]
              store.commit('setBookings', data)
            }
            store.commit('setBookingsLoad')
            if (
              Object.keys(store.getters.assistents).length ===
              store.getters.bookingsLoad
            ) {
              // store.commit('setBookingHash')
              callback()
            }
          }
        })
      })
    },
    changeEntryDate(store, data) {
      var bookings = store.getters.bookings[data.form]
      var user = Vue.prototype.$getUserVariables()

      var edit = 0
      for (var i = 0; i < bookings.length; i++) {
        if (bookings[i].ID * 1 === data.ID * 1) {
          edit = i
        }
      }

      Vue.prototype.$flix_post({
        url: 'booking/get',
        data: {
          user: user.user.md5_id,
          ID: data.form,
          booking_id: data.md5ID,
          options: { not_validated: true }
        },
        callback: function (ret) {
          if (ret.data[0]) {
            var r = {}
            bookings[edit] = ret.data[1][0]
            r[data.form] = bookings
            store.commit('setBookings', r)

            var d = { form: data.form, id: edit, entry: ret.data[1][0] }
            if (typeof data.callback === 'function') {
              data.callback(ret.data[1][0])
            }

            store.commit('setChangeEntryDate', d)
          }
        }
      })
    },
    changeEntryStatus(store, data) {
      var bookings = store.getters.bookings[data.form]
      var user = Vue.prototype.$getUserVariables()

      const savedData = Object.values(bookings).filter((book, index) => {
        book.index = index
        return String(book.id) === String(data.ID) ? book : false
      })

      const id = savedData.length
        ? savedData[0].index
        : Object.keys(bookings).length

      store.commit('setChangeEntryStatus', {
        form: data.form,
        id,
        entry: data
      })

      var send = {
        status: data.status,
        user: user.user.md5_id,
        event_id: data.form,
        ID: data.ID
      }

      send.note = data.note ? data.note : ''

      Vue.prototype.$flix_post({
        url: 'booking/change_status',
        data: send,
        callback: function (ret) {
          Vue.prototype.$flix_post({
            url: 'reminder/change_status',
            data: send,
            callback: function (ret2) {
            }
          })
        }
      })
    },
    changeEntryData(store, data) {
      var bookings = store.getters.bookings[data.form]
      for (var i = 0; i < bookings.length; i++) {
        if (bookings[i].ID === data.ID) {
          store.commit('setChangeEntryStatus', {
            form: data.form,
            id: i,
            entry: data
          })
          break
        }
      }
    }
  },
  mutations: {
    setDeletionList(store, data) {
      store.deletionList = data
    },
    setBookingUpdateKey(store, data) {
      store.bookingUpdate = data
    },
    addBooking(store, data) {
      if (typeof store.bookings[data.form] !== 'object') {
        store.bookings[data.form] = []
      }
      store.bookings[data.form].push(data.data)
    },
    setNewBooking(store, data) {
      store.newBooking = data
    },
    setDelete(store, data) {
      store.bookings[data.form].splice(data.id, 1)
      data.callback()
    },
    setBookings(store, data) {
      store.bookingsCount += Object.values(data).length
      store.bookings = Object.assign(store.bookings, data)
    },
    setChangeEntryStatus(store, data) {
      store.bookings[data.form][data.id] = data.entry
      store.bookingHash = new Date().getTime()
    },
    setChangeEntryDate(store, data) {
      store.bookingHash = new Date().getTime()
    },
    deleteBookings(store) {
      store.bookings = {}
      store.bookingHash = new Date().getTime()
    },
    setBookingHash(store) {
      store.bookingHash = new Date().getTime()
    },
    setBookingsLoad(store) {
      store.bookingsLoad += 1
    },
    deleteBookingsLoad(store) {
      store.bookingsLoad = 0
    }
  },
  getters: {
    deletionList(store) {
      return store.deletionList
    },
    newBooking(store) {
      return store.newBooking
    },
    bookings(store) {
      return store.bookings
    },
    bookingHash(store) {
      return store.bookingHash
    },
    bookingsCount(store) {
      return store.bookingsCount
    },
    bookingsLoad(store) {
      return store.bookingsLoad
    },
    bookingUpdateKey(store) {
      return store.bookingUpdate
    }
  }
}

export { bookings }
