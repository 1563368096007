import Vue from 'vue'

const targetpage = {
  state: {
    targetPages: []
  },
  actions: {
    setTargetPage (store, data) {
      var send = {
        user: data.user,
        ID: data.ID,
        data: JSON.stringify(data.data),
        meta: data.meta
      }
      Vue.prototype.$flix_post({
        url: 'pagebuilder/targetpage/save',
        data: send,
        callback: function (ret) { data.callback(ret) }
      })
    },
    getTargetPages (store, data) {
      Vue.prototype.$flix_post({
        url: 'pagebuilder/targetpage/get_all',
        data: {
          user: data.user
        },
        callback: function (ret) {
          if (ret.data[0]) {
            var r = []
            ret.data[1].forEach(function (v, k) {
              if (v.meta) {
                v.meta = JSON.parse(v.meta)
              }
              var d = {
                ID: v.page_id,
                method: 'targetPage',
                layout: JSON.parse(v.content),
                assistents: []
              }
              if (typeof v.meta.assistents === 'object') {
                d.assistents = v.meta.assistents
              }
              if (typeof v.meta.method === 'string') {
                d.method = v.meta.method
              }
              r.push(d)
            })
            data.callback(r)
          }
        }
      })
    },
    setSave (data) {

    }
  },
  mutations: {
    setSave (store, data) {
      store.targetPages = data
    }
  },
  getters: {
  }
}

export { targetpage }
