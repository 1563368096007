const base = 'shares'
const shares = [
  {
    path: '/' + base + '/calendars/:id.html',
    name: 'sharesAssistent',
    component: () => import('@/components/dashboard/parts/assistentList/')
  },
  {
    path: '/' + base + '/:ressource/:calendar/calendar.html',
    name: 'sharesCalendar',
    component: () => import('@/components/dashboard/parts/shares/')
  },
  {
    path: '/' + base + '/s/:passkey',
    name: 'shares',
    component: () => import('@/components/shares/')
  },
  {
    path: '/' + base + '/page-builder/:open/:id.html',
    name: 'sharesPageBuilder',
    component: () => import('@/components/dashboard/parts/pageBuilder/')
  },
  {
    path: '/' + base + '/:user/:form/:entryID/bookingDetails.html',
    name: 'sharesBookingDetails',
    component: () => import('@/components/dashboard/parts/bookingDetails')
  },
  {
    path: '/' + base + '/bookingCalendar.html',
    name: 'sharesBookingCalendar',
    component: () => import('@/components/dashboard/parts/bookingCalendar')
  },
  {
    path: '/' + base + '/bookingCalendar/:id/date/:date.html',
    name: 'sharesSpecificBookingCalendar',
    component: () => import('@/components/dashboard/parts/bookingCalendar')
  },
  {
    path: '/' + base + '/customer/add/:id.html',
    name: 'sharesAddCustomer',
    component: () => import('@/components/dashboard/parts/addCustomer')
  },
  {
    path: '/' + base + '/customer/:id.html',
    name: 'sharesCRM',
    component: () => import('@/components/dashboard/parts/crm/')
  },
  {
    path: '/' + base + '/bookingCalendar/date/:date.html',
    name: 'sharesBookingCalendarDate',
    component: () => import('@/components/dashboard/parts/bookingCalendar')
  },
  {
    path: '/' + base + '/bookingCalendar/:id/:time.html',
    name: 'sharesDateEntriesBookingCalendar',
    component: () => import('@/components/dashboard/parts/bookingCalendar')
  }
]

export { shares }
