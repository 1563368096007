const user = {
  state: {
    user: false
  },
  actions: {

  },
  mutations: {
    setUser (store, data) { store.user = data }
  },
  getters: {
    user (store) { if (!store.user && typeof localStorage.getItem('flix_login') && typeof localStorage.getItem('flix_login') === 'string') { return JSON.parse(atob(localStorage.getItem('flix_login'))) } return store.user }
  }
}

export { user }
