import user from '@/plugin/user'
import date from '@/plugin/date'
import post from '@/plugin/post'
import embed from '@/plugin/embed'
import string from '@/plugin/string'
import privateCalendar from '@/plugin/privateCalendar'
import components from '@/plugin/components'
import redirect from '@/plugin/redirect'
import permissions from '@/plugin/permissions'

export default {

  install (Vue, options) {
    user.install(Vue, options)
    date.install(Vue, options)
    post.install(Vue, options)
    embed.install(Vue, options)
    string.install(Vue, options)
    privateCalendar.install(Vue, options)
    components.install(Vue, options)
    redirect.install()
    permissions.install(Vue)
  }
}
