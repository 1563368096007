import Vue from 'vue'

const shares = {
  state: {
    shares: false,
    activeShare: false,
    shareNav: [0, 0],
    shareEmail: ''
  },
  actions: {
    getShares (store, data) {
      if (typeof store.shares === 'object') {
        store.commit('setShares', store.shares)
        store.commit('setActiveShare', [[0, 0], function () {}])
        return false
      }
      if (typeof localStorage.getItem('flix_login_share_email') === 'string') {
        var d = JSON.parse(atob(localStorage.getItem('flix_login_share_email')))
        data.email = d.email
      }
      Vue.prototype.$flix_post({
        url: 'shares/get',
        data: data,
        callback (ret) {
          if (ret.data[0]) {
            store.commit('setSharesUser', { data: ret.data[1][0].info, email: data.email })
            store.commit('setShares', ret.data[1])
          } else {
            store.commit('setShares', [])
          }
          data.callback()
        }
      })
    }
  },
  mutations: {
    setSharesUser (store, data) { localStorage.setItem('flix_login_share_email', btoa(JSON.stringify({ email: data.email }))); localStorage.setItem('flix_login_shares', btoa(JSON.stringify(data.data))) },
    setShares (store, data) { store.shares = data },
    setShareNav (store, data) { store.shareNav = data },
    setActiveShare (store, data) { store.activeShare = data[0]; setTimeout(function () { data[1]() }, 100) },
    setShareEmail (store, data) { store.shareEmail = data }
  },
  getters: {
    getShareCalendar (store) { if (typeof store.activeShare === 'undefined' || !store.activeShare || typeof store.activeShare[1] === 'undefined') { return false }; return { calendar: store.activeShare[1].calendar[store.activeShare[0]], info: store.activeShare[1].info } },
    getShareNav (store) { return store.shareNav },
    shareEmail (store) { return store.shareEmail },
    shares (store) { return store.shares }
  }
}

export { shares }
