import Vue from 'vue'
const calendar = {
  state: {
    calendarDate: new Date(),
    myPrivateCalendars: []
  },
  actions: {
    setSendPrivateEmail (store, data) {
      Vue.prototype.$flix_post({
        url: 'assistent/privates/sharingmail',
        data: data.data,
        callback: function (ret) {
          if (ret.data[0]) {
            data.callback()
            return false
          }
        }
      })
    }
  },
  mutations: {
    setCalendarDate (store, date) { store.calendarDate = date },
    setPrivateCalendar (store, data) { store.myPrivateCalendars.push(data) }
  },
  getters: {
    calendarDate (store) { return store.calendarDate },
    myPrivateCalendars (store) { return store.myPrivateCalendars }
  }
}

export { calendar }
