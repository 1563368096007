import Vue from 'vue'
import Vuex from 'vuex'
import { services } from '@/store/services.js'
import { messages } from '@/store/messages.js'
import { assistents } from '@/store/assistents.js'
import { search } from '@/store/search.js'
import { bookings } from '@/store/bookings.js'
import { calendar } from '@/store/calendar.js'
import { user } from '@/store/user.js'
import { reminder } from '@/store/reminder.js'
import { ressources } from '@/store/ressources.js'
import { shares } from '@/store/shares.js'
import { credits } from '@/store/credits.js'
import { targetpage } from '@/store/targetpage.js'
import { webinars } from '@/store/webinars.js'
import { organisations } from '@/store/organisations.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
    setCleanData (store, data) { store.assistents.assistents = []; store.assistents.assistentsByID = {}; store.bookings.bookings = {}; store.messages.messages = false; store.bookings.bookingHash = new Date().getTime(); store.search.search = { keyword: '', results: { bookings: [] } } },
    setLogout (store, data) {
      store.services.services = false
      store.assistents.assistents = {}
      store.assistents.assistentsByID = {}
      store.bookings.bookings = {}
      store.messages.messages = false
      store.bookings.bookingHash = new Date().getTime()
      store.shares.shares = false
      store.shares.activeShare = false
      store.ressources.ressources = false
      store.search.search = { keyword: '', results: { bookings: [] } }
      store.user.user = {}
      store.bookings.deletionList = 0
      store.credits.credits = false
      window.localStorage.removeItem('flix_login')
      window.localStorage.removeItem('flix_orga')
      window.localStorage.removeItem('flix_token')
    }
  },
  actions: {
    getLayout (store, data) {
      var send = {
        ID: data.ID
      }
      Vue.prototype.$flix_post({
        url: 'pagebuilder/mainpage/get',
        data: send,
        callback: function (ret) {
          if (typeof ret.data[1] === 'undefined') {
            data.callback(false)
            return false
          }
          data.callback(ret.data[1])
        }
      })
    }
  },
  modules: {
    reminder: reminder,
    services: services,
    messages: messages,
    assistents: assistents,
    search: search,
    bookings: bookings,
    calendar: calendar,
    user: user,
    ressources: ressources,
    shares: shares,
    credits: credits,
    webinars: webinars,
    targetpage: targetpage,
    organisations: organisations
  },
  getters: {
  }
})
