import Vue from 'vue'

const services = {
  state: {
    services: false,
    serviceKey: new Date().getTime()
  },
  mutations: {
    setServices(store, data) {
      store.services = data
      store.serviceKey = new Date().getTime()
    }
  },
  actions: {
    getServices(store, callback) {
      if (Object.keys(store.getters.services).length) {
        callback(store.getters.services)
        return false
      }
      Vue.prototype.$flix_post({
        url: 'assistent/get_services',
        data: {
          user: store.getters.user.md5_id
        },
        callback: function (ret) {
          if (ret.data[0]) {
            store.commit('setServices', ret.data[1])
            callback(ret.data[1])
          }
        }
      })
    },
    setChangeService(store, data) {
      var newService = {}
      newService[data[0]] = data[1]
      var service = Object.assign(store.getters.services, newService)
      store.commit('setServices', service)
      service = JSON.parse(JSON.stringify(service))
      Object.keys(service).forEach(function (k) {
        service[k].short = JSON.stringify(service[k].short)
        delete service[k].ID
      })
      Vue.prototype.$flix_post({
        url: 'assistent/save_services',
        data: {
          user: store.getters.user.md5_id,
          data: service
        },
        callback: function (ret) {}
      })
    },
    setAddService(store, data) {
      store.commit('setServices', data)
      var service = JSON.parse(JSON.stringify(data))
      Object.keys(service).forEach(function (k) {
        service[k].short = JSON.stringify(service[k].short)
        delete service[k].ID
      })
      Vue.prototype.$flix_post({
        url: 'assistent/save_services',
        data: {
          user: store.getters.user.md5_id,
          data: service
        },
        callback: function (ret) {}
      })
    }
  },
  getters: {
    services(store) {
      return store.services
    },
    serviceKey(store) {
      return store.serviceKey
    }
  }
}

export { services }
