import Vue from 'vue'
import FlixCalBold from 'bookingflix_calendar_bold'
import FlixCalCalendly from 'bookingflix_calendar_clndly'
import calendar from 'bookingflix_calendar'
import pagebuilder from 'bookingflix_pagebuilder'
import statistic from 'bookingflix_statistic'
import chartist from 'v-chartist'

window.Vue = Vue
Vue.use(pagebuilder)
Vue.use(calendar)
Vue.use(FlixCalCalendly)
Vue.use(FlixCalBold)
Vue.use(statistic)
Vue.component('chartist', chartist, { name: 'VueChartist' })
