export default {
  install (Vue, options) {
    Vue.component('flixIcon', function () { return import('@/components/assets/flix_glyphicon.vue') })
    Vue.component('flixLoader', function () { return import('@/components/assets/loader.vue') })
    Vue.component('flixDashboard', function () { return import('@/components/dashboard/index.vue') })
    Vue.component('flixExpand', function () { return import('@/components/assets/expand.vue') })
    Vue.component('addBtn', function () { return import('@/components/assets/addBtn.vue') })
    Vue.component('saveBtn', function () { return import('@/components/assets/saveBtn.vue') })
    Vue.component('backBtn', function () { return import('@/components/assets/backBtn.vue') })
    Vue.component('editBtn', function () { return import('@/components/assets/editBtn.vue') })
    Vue.component('toggle', function () { return import('@/components/assets/toggle.vue') })
    Vue.component('logo', function () { return import('@/components/assets/logo.vue') })
    Vue.component('flixUpload', function () { return import('@/components/assets/upload.vue') })
    Vue.component('flixPopup', function () { return import('@/components/assets/popup.vue') })
    Vue.component('flixColorpicker', function () { return import('@/components/assets/colorpicker.vue') })
    Vue.component('flixCheckPermissions', function () { return import('@/components/permissions/checkPermissions.vue') })
  }
}
