import Vue from 'vue'
import VueRouter from 'vue-router'
import { dashboard } from '@/router/dashboard.js'
import { events } from '@/router/events.js'
// import { frontend } from '@/router/frontend.js'
import { admin } from '@/router/admin.js'
import { shares } from '@/router/shares.js'
import { go } from '@/router/go.js'

Vue.use(VueRouter)

var routes = [{
  path: '/sign-in.html',
  name: 'signIn',
  component: () => import('@/components/signIn')
},
{
  path: '/:lang/sign-in.html',
  name: 'signInTranslation',
  component: () => import('@/components/signIn')
}]

routes = routes.concat(go)
routes = routes.concat(dashboard)
routes = routes.concat(events)
// routes = routes.concat(frontend)
routes = routes.concat(admin)
routes = routes.concat(shares)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
