import Vue from 'vue'

const assistents = {
  state: {
    assistentUpdateKey: 0,
    assistentActive: false,
    assistents: [],
    assistentsByID: {},
    colors: [
      '#4A148C',
      '#E65100',
      '#0D47A1',
      '#827717',
      '#212121',
      '#880E4F',
      '#3E2723',
      '#B71C1C'
    ],
    webinar: [],
    currentMonth: undefined
  },
  actions: {
    getPrivateAssistent (store, data) {
      Vue.prototype.$flix_post({
        url: 'assistent/privates/get',
        data: { ID: data.id },
        callback: function (ret) {
          if (ret.data[0]) {
            data.callback(ret.data[1])
          }
        }
      })
    },
    setAssistent (store, data) {
      var user = Vue.prototype.$getUserVariables()
      Vue.prototype.$flix_post({
        url: 'assistent/save',
        data: {
          user: user.user.md5_id,
          ID: data.ID,
          data: JSON.stringify(data.data)
        },
        callback: function (ret) {
          if (typeof data.data.styling === 'object' && typeof data.data.styling.accent === 'string') {
            var color = { ID: data.ID }
            color.color = data.data.styling.accent
            store.commit('setAssistentColor', color)
          }
          data.callback(ret)
        }
      })
    },
    setCloneAssistent (store, data) {
      var user = Vue.prototype.$getUserVariables()
      Vue.prototype.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: user.user.md5_id,
          ID: data.ID
        },
        callback: function (ret) {
          var r = ret.data[1]
          r.title = r.title + ' copy '
          delete r.exclude.bookings
          r.ID = new Date().getTime() + ''
          data.callback(r)
        }
      })
    },
    setStandardReminder (store, data) {
      Vue.prototype.$flix_post({
        url: 'reminder/standard',
        data: {
          user: store.getters.user.md5_id,
          ID: data.ID
        },
        callback: function (ret) {
          data.callback(ret)
        }
      })
    },
    getAssistents (store, callback) {
      if (typeof store.getters.assistents === 'object' && Object.keys(store.getters.assistents).length) {
        callback()
        return false
      }

      Vue.prototype.$flix_post({
        url: 'assistent/get_assistents',
        data: { user: store.getters.user.md5_id },
        callback: function (ret) {
          if (ret.data[0]) {
            this.commit('setAssistents', ret.data[1])
          }
          callback()
        }.bind(this)
      })
    }
  },
  mutations: {
    cleanAssistents (store) { store.assistents = []; store.assistentsByID = {} },
    setAssistentCurrentMonth (store, month) { store.currentMonth = month },
    setAssistentUpdateKey (store) { store.assistentUpdateKey = new Date().getTime() },
    setActiveAssistent (store, data) { store.assistentActive = data },
    setAssistents (store, data) { Object.keys(data).forEach(function (k) { store.assistentsByID[data[k].ID] = data[k] }); store.assistents = data },
    setAssistentColor (store, data) { store.assistents.forEach(function (a, b) { if (a.ID === data.ID) { store.assistents[b].color = data.color } }) }
  },
  getters: {
    assistents (store) { return store.assistents },
    assistentsByID (store) { return store.assistentsByID },
    colors (store) { return store.colors }
  }
}

export { assistents }
