import Vue from 'vue'

const ressources = {
  state: {
    ressources: false,
    ressourcesConnect: {}
  },
  actions: {
    getRessources (store, callback) {
      if (store.state.ressources) {
        callback(store.state.ressources)
      }
      Vue.prototype.$flix_post({
        url: 'ressources/get',
        data: {
          user: store.getters.user.md5_id
        },
        callback (ret) {
          if (ret.data[0]) {
            store.commit('setRessources', ret.data[1])
            store.dispatch('getConnectRessources', callback)
          } else {
            store.commit('setRessources', [])
          }
        }
      })
    },
    getConnectRessources (store, callback) {
      if (!store.state.ressources.length) {
        callback(store.state.ressources)
        return false
      }
      store.state.ressources.forEach(function (k) {
        Vue.prototype.$flix_post({
          url: 'ressources/connect/get',
          data: {
            user: store.getters.user.md5_id,
            ID: k.ID
          },
          callback (ret) {
            var data = {}
            data[k.ID] = {}
            if (ret.data[0]) {
              data[k.ID] = ret.data[1]
              store.commit('setRessourcesConnect', ret.data[1])
            } else {
              data[k.ID] = {}
              store.commit('setRessourcesConnect', data)
            }
            if (k.ID === store.state.ressources[store.state.ressources.length - 1].ID) {
              callback(store.state.ressources)
            }
          }
        })
      })
    }
  },
  mutations: {
    setRessources (store, data) { store.ressources = data },
    setRessourcesConnect (store, data) { store.ressourcesConnect = Object.assign(store.ressourcesConnect, data) }
  },
  getters: {
    ressources (store) { return store.ressources },
    ressourcesConnect (store) { return store.ressourcesConnect }
  }
}

export { ressources }
