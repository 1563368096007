const Permission = function () {
  this.role = 'abonnement'
  this.active = false
  this.required = 3

  this.check = function (attr) {
    if (!this.checkOrgansisation()) {
      return 3
    }
    if (!this.checkRole()) {
      return 0
    }
    this.loadJSON()
    return this.checkRequired(this.rights[attr] ? (this.rights[attr] * 1) : 0)
  }
  this.checkRequired = function (right) {
    if (this.required * 1 <= right * 1) {
      return true
    }
    return false
  }
  this.checkRole = function () {
    if (!this.active) {
      return false
    }
    return true
  }
  this.checkOrgansisation = function () {
    if (!this.role) {
      return false
    }
    return true
  }
  this.loadJSON = function () {
    this.rights = require('@/components/permissions/rules/json/' + this.role + '.json')
  }
}

export default {
  install (Vue, options) {
    Vue.prototype.$checkPermission = function (attr, required) {
      const p = new Permission()
      p.required = required
      p.active = this.$store.state.organisations.organisationActive
      p.role = this.$store.getters.organisationName.role

      return p.check(attr)
    }
  }
}
