import Vue from 'vue'

const webinars = {
  namespaced: false,
  state: {
    webinars: []
  },
  actions: {
    getWebinars (store, data) {
      Vue.prototype.$flix_post({
        url: '/webinar/bookingflix/get_all',
        data: { user: store.getters.user.md5_id },
        callback: function (ret) {
          if (!ret.data[0]) {
            return false
          }
          store.commit('setWebinars', { data: ret.data[1], callback: data.callback })
        }
      })
    }
  },
  mutations: {
    setWebinars (store, data) {
      store.webinars = data.data
      data.callback(data.data)
    }
  }
}

export { webinars }
