const events = [
  {
    path: '/iframe/calendar/:user/:id.html',
    name: 'iframeCalendar',
    component: () => import('@/components/iframe/calendar')
  },
  {
    path: '/:lang/:username/:id/iframe.html',
    name: 'iframeExternalCalendar',
    component: () => import('@/components/iframe/externalCalendar')
  },
  {
    path: '/iframe/chat/:user/:id.html',
    name: 'iframeInteressentChat',
    component: () => import('@/components/iframe/chat')
  },
  {
    path: '/:lang/:username/:id/:style.html',
    name: 'bookingPage',
    component: () => import('@/components/bookingPage/')
  },
  {
    path: '/validate/:user.html',
    name: 'validateUser',
    component: () => import('@/components/signIn/validate')
  },
  {
    path: '/check-in/:id',
    name: 'checkIn',
    component: () => import('@/components/checkIn/page/')
  },
  {
    path: '/reset-password/:id',
    name: 'resetPassword',
    component: () => import('@/components/signIn/resetPassword')
  },
  {
    path: '/pe/:id',
    name: 'privateEvent',
    component: () => import('@/components/privateEvent/')
  },
  {
    path: '/pe/:id/edit.html',
    name: 'privateEventEdit',
    component: () => import('@/components/privateEvent/editor')
  },
  {
    path: '/pe/pdf/:id/info.pdf',
    name: 'privateEventPDF',
    component: () => import('@/components/privateEvent/pdf')
  },
  {
    path: '/:lang/booking-details/:user/:id/:page.html',
    name: 'bookingInfopage',
    component: () => import('@/components/bookingInfoPage/')
  },
  {
    path: '/unsubscribe/:id.html',
    name: 'unsubscribeEmail',
    component: () => import('@/components/unsubscribe/')
  }
]

export { events }
